import React from 'react';
import Layout from '../components/Layout';
import ServicesTemplate from '../templates/ServicesTemplate';
import { graphql } from 'gatsby';

const ServicesPage = ({ data }) => {
	const businessServices = data.businessServices.edges.map((edge) => ({
		name: edge.node.display_name,
		detail_list: edge.node.detail_list,
		slug: edge.node.fields.slug,
		image: edge.node.tile_image,
	}));
	const lendersServices = data.lendersServices.edges.map((edge) => ({
		name: edge.node.display_name,
		detail_list: edge.node.detail_list,
		slug: edge.node.fields.slug,
		image: edge.node.tile_image,
	}));
	return (
		<Layout>
			<ServicesTemplate
				businessServices={businessServices}
				lendersServices={lendersServices}
			/>
		</Layout>
	);
};

export default ServicesPage;

export const query = graphql`
	query {
		businessServices: allServicesJson(
			filter: { for: { eq: "business owners" } }
			sort: { order: ASC, fields: [order] }
		) {
			edges {
				node {
					display_name
					detail_list {
						heading
					}
					tile_image
					fields {
						slug
					}
				}
			}
		}
		lendersServices: allServicesJson(
			filter: { for: { eq: "lenders and creditors" } }
			sort: { order: ASC, fields: [order] }
		) {
			edges {
				node {
					display_name
					detail_list {
						heading
					}
					tile_image
					fields {
						slug
					}
				}
			}
		}
	}
`;
