import React from 'react';
import { Helmet } from 'react-helmet';

import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';
import ExpertFinder from '../components/ExpertFinder';
import SideImageInfoSection from '../components/SideImageInfoSection';

import SolutionsSection from '../components/SolutionsSection';
import { PageWrap } from '../styles/common';

export default ({ businessServices, lendersServices }) => {
	return (
		<PageWrap>
			<Helmet>
				<title>Services | Spergel Corporate</title>
			</Helmet>
			<Masthead
				image="/img/SpergelCorporate_Services_Masthead.jpg"
				title="Our Services"
				mobile="/img/SpergelCorporate_Services_Mobile.jpg"
			/>
			<SideImageInfoSection
				image={`/img/SpergelCorporate_MeaningfulSolutions.jpg`}
				imageAlt={`Meaningful solutions.`}
				isLeft={false}
				mainContent={`## Strategic Debt Solutions, Restructuring and Consulting Services for Your Business\n\nFor over 30 years, our Licensed Insolvency Trustees have been helping all size of businesses identify and solve their business debt problems. Our breadth of restructuring experience can help your business explore all possible business debt solutions. Whether you need operational and financial restructuring, insolvency or advisory services – let our team help you discover strategic and cost-effective solutions.`}
			/>
			<SolutionsSection
				businessServices={businessServices}
				lendersServices={lendersServices}
			/>
			<ExpertFinder />
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};
