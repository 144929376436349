import React, { useState } from 'react';
import styled from 'styled-components';

import ServicesTiles from './ServicesTiles';

import Select from 'react-select';

// react-select style API.
const customStyles = {
	control: (provided) => ({
		...provided,
		width: '100%',
		fontSize: '1.5rem',
		backgroundColor: 'transparent',
		borderRadius: 0,
		border: 'none',
		borderBottom: '1px solid #ddd',
		paddingBottom: '0.5rem',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		opacity: 1,
		color: 'black',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
};

export default ({ businessServices, lendersServices }) => {
	const [isBusiness, setIsBusiness] = useState(true);
	return (
		<Wrap isBusiness={isBusiness}>
			<PanelWrap>
				<SolutionsWrap isBusiness={isBusiness}>
					<span className="solutions-for-label">Solutions for </span>
					<div
						className="drop-wrap"
						style={isBusiness ? { maxWidth: '15rem' } : { maxWidth: '19rem' }}
					>
						<Select
							options={[
								{
									value: 'business',
									label: 'Business Owners',
								},
								{
									value: 'lenders',
									label: 'Lenders and Creditors',
								},
							]}
							defaultValue={{
								value: 'business',
								label: 'Business Owners',
							}}
							styles={customStyles}
							isSearchable={false}
							placeholder={'Service'}
							onChange={(e) => {
								setIsBusiness(e.value === 'business');
							}}
						/>
					</div>
					<div className="business">
						<ServicesTiles services={businessServices} />
					</div>
					<div className="lenders">
						<ServicesTiles services={lendersServices} />
					</div>
				</SolutionsWrap>
			</PanelWrap>
		</Wrap>
	);
};

const Wrap = styled.div`
	padding: 2rem 0;
	background-color: #f2f2f2;
	@media only screen and (min-width: 768px) {
		padding: 4rem 0;
	}
	h3 {
		font-size: 1.25rem;
		@media only screen and (min-width: 768px) {
			font-size: 1.5rem;
		}

		color: white;
		display: inline-block;
		padding: 0.5rem 0;
		cursor: pointer;
	}
	.business-heading {
		border-bottom: ${(p) =>
			p.isBusiness ? '2px solid #3B4254' : '2px solid transparent'};
		opacity: ${(p) => (p.isBusiness ? '1.0' : '0.3')};
	}
	.lenders-heading {
		border-bottom: ${(p) =>
			p.isBusiness ? '2px solid transparent' : '2px solid #3B4254'};
		opacity: ${(p) => (p.isBusiness ? '0.3' : '1.0')};
	}
`;

const PanelWrap = styled.div`
	width: 100%;
`;

const SolutionsWrap = styled.div`
	width: 100%;
	margin: 0 auto;
	text-align: center;
	.solutions-for-label {
		font-size: 1.5rem;
	}
	ul {
		list-style-type: none;
		padding-left: 0;
		li {
			font-size: 1rem;
			margin-bottom: 1rem;
			@media only screen and (min-width: 768px) {
				font-size: 1.5rem;
				margin-bottom: 1.5rem;
			}
			cursor: pointer;
			a {
				color: inherit;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.drop-wrap {
		display: inline-block;
		font-size: 1.5rem;
		font-weight: 700;
		width: 100%;
		margin-bottom: 2rem;
	}
	.business {
		display: ${(p) => (p.isBusiness ? 'block' : 'none')};
	}
	.lenders {
		display: ${(p) => (p.isBusiness ? 'none' : 'block')};
	}
`;
