import React from 'react';
import styled from 'styled-components';

import common from '../styles/common';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';

import ServiceIndustryTile from './ServiceIndustryTile';
import Button from './Button';

// Split array in half.
// Larger half will always be first.
// Returns array of halves.
function splitArrayInHalf(arr) {
	const half = Math.ceil(arr.length / 2);
	const firstHalf = arr.slice(0, half);
	const secondHalf = arr.slice(half);
	return [firstHalf, secondHalf];
}

const Inner = styled.div`
	@media ${common.devices.desktop} {
		margin-top: ${(p) => p.offset};
	}
`;

export default ({ services }) => {
	const [first, second] = splitArrayInHalf(services);
	return (
		<Wrap>
			<div className="container">
				<div className="columns is-gapless">
					<div className="column">
						<Inner>
							{first.map((industry, index) => (
								<ServiceIndustryTile
									title={industry.name}
									image={industry.image}
									slug={industry.slug}
									key={index}
								/>
							))}
						</Inner>
					</div>
					<div className="column">
						<Inner offset={'4rem'}>
							{second.map((industry, index) => (
								<ServiceIndustryTile
									title={industry.name}
									image={industry.image}
									slug={industry.slug}
									key={index}
								/>
							))}
						</Inner>
					</div>
				</div>
				<BottomWrap>
					<p>Can't find the service you are looking for?</p>
					<a href="/team">
						<Button>TALK TO A TRUSTEE</Button>
					</a>
				</BottomWrap>
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	padding: 2rem 0;
`;

const BottomWrap = styled.div`
	text-align: center;
	padding: 2rem 0;
	p {
		font-weight: 700;
	}
`;
